import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-Mikro 380",
  "path": "/Products/IN-Mikro/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The IN-MIKRO 380 is a high quality active microphone which can be easily connected to your IP Camera. All you need is an IP Camera device which has a built-in audio input.",
  "image": "./P_SearchThumb_Microphones.png",
  "social": "/images/Search/P_SearchThumb_Microphones.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-Mikro_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-Mikro 380' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-MIKRO 380 is a high quality active microphone which can be easily connected to your IP Camera. All you need is an IP Camera device which has a built-in audio input.' image='/images/Search/P_SearchThumb_Microphones.png' twitter='/images/Search/P_SearchThumb_Microphones.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/IN-Mikro/' locationFR='/fr/Products/IN-Mikro/' crumbLabel="IN-Mikro" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "microphones",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#microphones",
        "aria-label": "microphones permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Microphones`}</h1>
    <h2 {...{
      "id": "in-mikro-380",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-mikro-380",
        "aria-label": "in mikro 380 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-Mikro 380`}</h2>
    <p>{`The IN-MIKRO 380 is a high quality active microphone which can be easily connected to your IP Camera. All you need is an IP Camera device which has a built-in audio input. The following models are currently supported: IN-2901, IN-2905 V2 (year of manufacture 2013), IN-2908, IN-5907, IN-4010, IN-4011, IN-5907HD and the new introduced IN-7011HD. Please take notice, that in order to use this microphone with the IN-4010, In-4011, IN-5907HD or the IN-7011HD you will need to use a different Y-cable for 12V power supplies then for the 5V models.`}</p>
    <p><strong parentName="p">{`Your Advantages`}</strong></p>
    <ul>
      <li parentName="ul">{`Background noises will be reduced (in comparison to a simple included mic)`}</li>
      <li parentName="ul">{`Clear and pure sound transmission`}</li>
      <li parentName="ul">{`Easy connection to your camera through Cinch`}</li>
      <li parentName="ul">{`Adjustable sensitivity of the microphone`}</li>
    </ul>
    <p><strong parentName="p">{`Content of delivery`}</strong></p>
    <ul>
      <li parentName="ul">{`1x Microphone IN-MIKRO 380`}</li>
      <li parentName="ul">{`1x Y-cable for 12V IP Cameras`}</li>
      <li parentName="ul">{`1x Screws and dowels`}</li>
      <li parentName="ul">{`1x User manual`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b20099011138f54a79c3a2c382eb6236/f93b5/IN-Mikro_380_00.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAEDBQIE/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB910ZprrR3AAf/8QAGhAAAwADAQAAAAAAAAAAAAAAAQIDABASE//aAAgBAQABBQKjuDIt3540m6rSsnVgy7//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAbEAACAgMBAAAAAAAAAAAAAAAAARExAhAgIv/aAAgBAQAGPwLyOcpReqaJVcf/xAAcEAEAAwACAwAAAAAAAAAAAAABABEhMUEgcaH/2gAIAQEAAT8h369+RFhRgnE5LfW4ixSe4SxnYWQk9rvw/9oADAMBAAIAAwAAABDzBzz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAxIUFREKH/2gAIAQEAAT8QgYNhfXMneC7ShDY77wdzvs4/cZshZolccCSGgL7I4O8tB8d4AECGf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b20099011138f54a79c3a2c382eb6236/e4706/IN-Mikro_380_00.avif 230w", "/en/static/b20099011138f54a79c3a2c382eb6236/e47c0/IN-Mikro_380_00.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b20099011138f54a79c3a2c382eb6236/a0b58/IN-Mikro_380_00.webp 230w", "/en/static/b20099011138f54a79c3a2c382eb6236/c85cb/IN-Mikro_380_00.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b20099011138f54a79c3a2c382eb6236/e83b4/IN-Mikro_380_00.jpg 230w", "/en/static/b20099011138f54a79c3a2c382eb6236/f93b5/IN-Mikro_380_00.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b20099011138f54a79c3a2c382eb6236/f93b5/IN-Mikro_380_00.jpg",
              "alt": "INSTAR IN-Mikro 380 Microphone for your IP Camera",
              "title": "INSTAR IN-Mikro 380 Microphone for your IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h2>
    <h3 {...{
      "id": "how-to-connect-the-microphone-with-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-connect-the-microphone-with-your-camera",
        "aria-label": "how to connect the microphone with your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to connect the Microphone with your Camera?`}</h3>
    <p>{`The IN-Mikro 380 microphone is powered by your camera´s power supply via a Y-cable that is in the scope of the delivery. The wiring has to be according to the sketch below:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c5c746c9b5f61f9e37056ec96e90e837/00d43/IN-Mikro_380_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABU0lEQVQoz41R207CQBDt//8ML4qRi7XFJ0GhgBXohbDtlrYmLbdgU3bnmG7VQHyQk5yc2Z0zk5ld7d22qdNuwzQMLGYzEI8gWKBYxdmbDUc3MG7e4WM8Ac7ykkd4fe6j0WhgPp+jgpamKdm2Dc45lZ8FyZCTYAGJIFSKJCUaWgRrouLznOQRrTyfTMOk0XBIu92ONACEGj/6BzJOIJMU/0EIAY2kJCkl5LfSGaUQypgzhjwI6+ZCXHiorlUkIlw1IY8iROs1roHGGCO7eviFQ5xzbLdb5HmOw+GgWJYlHMeB57ooigLH4xH7/V55NpsNlsslrNEIL4MB2GoFzfd9at+3MOj3KeIRPM9TDfTuAx51HT3DRLvVQrfTUefmza0qrnyu68KyLDz1eio3nU6hCSFI1G91sXKWZUiTFGEYIo5jpEmi7gLG1OS/HyYlTqeT0mrlL9xPWX+9KyInAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5c746c9b5f61f9e37056ec96e90e837/e4706/IN-Mikro_380_04.avif 230w", "/en/static/c5c746c9b5f61f9e37056ec96e90e837/d1af7/IN-Mikro_380_04.avif 460w", "/en/static/c5c746c9b5f61f9e37056ec96e90e837/7f308/IN-Mikro_380_04.avif 920w", "/en/static/c5c746c9b5f61f9e37056ec96e90e837/445fc/IN-Mikro_380_04.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c5c746c9b5f61f9e37056ec96e90e837/a0b58/IN-Mikro_380_04.webp 230w", "/en/static/c5c746c9b5f61f9e37056ec96e90e837/bc10c/IN-Mikro_380_04.webp 460w", "/en/static/c5c746c9b5f61f9e37056ec96e90e837/966d8/IN-Mikro_380_04.webp 920w", "/en/static/c5c746c9b5f61f9e37056ec96e90e837/a5d4d/IN-Mikro_380_04.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5c746c9b5f61f9e37056ec96e90e837/81c8e/IN-Mikro_380_04.png 230w", "/en/static/c5c746c9b5f61f9e37056ec96e90e837/08a84/IN-Mikro_380_04.png 460w", "/en/static/c5c746c9b5f61f9e37056ec96e90e837/c0255/IN-Mikro_380_04.png 920w", "/en/static/c5c746c9b5f61f9e37056ec96e90e837/00d43/IN-Mikro_380_04.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c5c746c9b5f61f9e37056ec96e90e837/c0255/IN-Mikro_380_04.png",
              "alt": "INSTAR IN-Mikro 380 Microphone for your IP Camera",
              "title": "INSTAR IN-Mikro 380 Microphone for your IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
You can power the microphone with the power supply from your camera.`}</p>
    <p>{`And that is how it will look in a less abstract way:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/00d43/IN-Mikro_380_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABtUlEQVQ4y61Sy0rDQBQdFVyJXyD4GyIufO1El8WN/oLfUEUqaFVQS612041Va0SwaYpVtKCoG0FIH8bWdGMSEUIXprE2PZKxqenDouCFIXdO5p4598wlsEW5XK5+S6USzVVVRSqdBp9IIP0gIJPNQlaU6ll7rblIMzLDMGieTKVweXWFZ0mC/v6OfD4PUcwhkUwiwnGIcFFc39zSc1aQn5Qtut1wzs7RXFFe4HItIBjcrVGk6zoeMxmcnp0hzLIwLIWWXIvsgDnE8PAIZEmie+fcPAghaO/oxNj4BDRNQ33s7YcoTirXVduMx+PweL0oFot0b/rV1z8A0tZBSaenp75wWQHP8ziJxbDl90PM5b5bNiotS6+vWPF4oGuF6s0OxyQl6uruxujQIC7DOwjtB7G05MYBw+D84oJ6S3kMo0L48fH1CIEATtc3IMiy+ZdiHBfFps8HQRAapsEeVoc1hOLaOtieXhyzUYiS0rTIKrSmwfTd/qg1hE/MIWYIAbPtx/LqAoTkPcVNP+sLm6lsGJs3VUWUY6FpBRwF/eDvrmva+U2QVr60wlsS1g+25Y8d/7PC/4pPU1W8FS7z8zIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/e4706/IN-Mikro_380_02.avif 230w", "/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/d1af7/IN-Mikro_380_02.avif 460w", "/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/7f308/IN-Mikro_380_02.avif 920w", "/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/445fc/IN-Mikro_380_02.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/a0b58/IN-Mikro_380_02.webp 230w", "/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/bc10c/IN-Mikro_380_02.webp 460w", "/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/966d8/IN-Mikro_380_02.webp 920w", "/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/a5d4d/IN-Mikro_380_02.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/81c8e/IN-Mikro_380_02.png 230w", "/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/08a84/IN-Mikro_380_02.png 460w", "/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/c0255/IN-Mikro_380_02.png 920w", "/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/00d43/IN-Mikro_380_02.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fc82c7c10f4ed2f5d4ad3b82107b620c/c0255/IN-Mikro_380_02.png",
              "alt": "INSTAR IN-Mikro 380 Microphone for your IP Camera",
              "title": "INSTAR IN-Mikro 380 Microphone for your IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
Take the microphone out of the box...`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f16a28c7c2fe1235ce79de64da6ff71a/00d43/IN-Mikro_380_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABkklEQVQ4y6VTu04CQRRdP0f+wwiNgJWauH4AFlhA7H1GIolKTEgoFB+ANagIMSIGokZZ3U7XQjahAYSK8NjlmBkzk32gjSeZnZ3HPffMPTMCAOi6Tjq0220cHZ9gZnYO4w4HfL5FJFNnSCSSSCRTSGfOUW806F5N02g/HA55IxDYD8H6xiZui0VUq1VIkoROp8PXCMGrLGNnL4JSuWwjZb3A1H1WVaysrsEKY3aGrVAIsiyPJOUKa7UaDg7juH945IGDwYA2EqRrGvr9Pp2v1+uYFxdMyhg4IanNVT6P50oF2VzOdFwjmCKny4VAcJnPmRSywcVlFr1eD++KQk3weL3weKepOYWba3w1m9D1n73RaBRjggBVVcGMpTUkH5Z1OxyGoihcTTqTgd+/BFEUkTqN40N542utVgv7kV16fFsN2WBi0olYLMbrNwqjTLLVkDk95XYjGAhwQqKcm6JrpkB2MqsxJsIX6Qmlu4KpJlY1o1QZIVgvZrfbtQUYX8NfSUzXhm60ZP8r8FdCK8F/yAjhN/FUzD1bfeM3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f16a28c7c2fe1235ce79de64da6ff71a/e4706/IN-Mikro_380_03.avif 230w", "/en/static/f16a28c7c2fe1235ce79de64da6ff71a/d1af7/IN-Mikro_380_03.avif 460w", "/en/static/f16a28c7c2fe1235ce79de64da6ff71a/7f308/IN-Mikro_380_03.avif 920w", "/en/static/f16a28c7c2fe1235ce79de64da6ff71a/445fc/IN-Mikro_380_03.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f16a28c7c2fe1235ce79de64da6ff71a/a0b58/IN-Mikro_380_03.webp 230w", "/en/static/f16a28c7c2fe1235ce79de64da6ff71a/bc10c/IN-Mikro_380_03.webp 460w", "/en/static/f16a28c7c2fe1235ce79de64da6ff71a/966d8/IN-Mikro_380_03.webp 920w", "/en/static/f16a28c7c2fe1235ce79de64da6ff71a/a5d4d/IN-Mikro_380_03.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f16a28c7c2fe1235ce79de64da6ff71a/81c8e/IN-Mikro_380_03.png 230w", "/en/static/f16a28c7c2fe1235ce79de64da6ff71a/08a84/IN-Mikro_380_03.png 460w", "/en/static/f16a28c7c2fe1235ce79de64da6ff71a/c0255/IN-Mikro_380_03.png 920w", "/en/static/f16a28c7c2fe1235ce79de64da6ff71a/00d43/IN-Mikro_380_03.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f16a28c7c2fe1235ce79de64da6ff71a/c0255/IN-Mikro_380_03.png",
              "alt": "INSTAR IN-Mikro 380 Microphone for your IP Camera",
              "title": "INSTAR IN-Mikro 380 Microphone for your IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
...connect it to the Y-cabel that comes with the microphone and use the other two connectors of the cable to make a bridge between the power supply and the camera ...`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7c3f40b66d74a5deceab224b5e2dcabe/00d43/IN-Mikro_380_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAAB2UlEQVQ4y4VTXUvbUBg+v0Lwag79D9J/IHg9b0UvRlH8B7LBmBejeCH0Tr2WOVovNLpOWLG2la3ph0LbxKZJCxVSTJt+YFpp0mec404XQ+heOAm873Oe87xfBFNsPB6zQ81xHIxGI9iOM/Hbts38bizxXuR/L/E0o8QcR+jHcb3qJuAxaleJBD593sGZIKDdbkPTNOwfHECW5b+kLzjip8ZxPcIJ3weDmJmdxebWJoxWi/mCGxtYDATw4/JyUhYyfH7G6to6xKw4cfqZZVlMWbPZhKpp+BmPI5vL4UsohEg0yjC0xmT/8BBv5xewtLyMgWWxQOPhAfL9PZRqFRffYzgVBJTKZRRLJaiqyghv7+7Q7/cZvlpVkclkXlIO7e5i+8NHplKIxRC/TkCSJNTqdfR6PbRNE4PB4FX6w+EQFUVBsVRkxCsr7/Bmbg5arQZCpSdTKRimiUdJws3JydSOUpUU/2gYE59jjyCKIlNOrq6T+BaNwLCekD+/QO3Xb1+ibreLZDrN1PvNKTfWZZpSyzTR0HWUKxUcfT3GXjjM0qE1yxcKSN2k0el0fIncQ078hpZ2S1EUSLLMmqPr+r/0PJvhq5AH+Oz9bw2nbRXxWzFOzo/b71XmVfgHoX/IOQTCh/YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7c3f40b66d74a5deceab224b5e2dcabe/e4706/IN-Mikro_380_01.avif 230w", "/en/static/7c3f40b66d74a5deceab224b5e2dcabe/d1af7/IN-Mikro_380_01.avif 460w", "/en/static/7c3f40b66d74a5deceab224b5e2dcabe/7f308/IN-Mikro_380_01.avif 920w", "/en/static/7c3f40b66d74a5deceab224b5e2dcabe/445fc/IN-Mikro_380_01.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7c3f40b66d74a5deceab224b5e2dcabe/a0b58/IN-Mikro_380_01.webp 230w", "/en/static/7c3f40b66d74a5deceab224b5e2dcabe/bc10c/IN-Mikro_380_01.webp 460w", "/en/static/7c3f40b66d74a5deceab224b5e2dcabe/966d8/IN-Mikro_380_01.webp 920w", "/en/static/7c3f40b66d74a5deceab224b5e2dcabe/a5d4d/IN-Mikro_380_01.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7c3f40b66d74a5deceab224b5e2dcabe/81c8e/IN-Mikro_380_01.png 230w", "/en/static/7c3f40b66d74a5deceab224b5e2dcabe/08a84/IN-Mikro_380_01.png 460w", "/en/static/7c3f40b66d74a5deceab224b5e2dcabe/c0255/IN-Mikro_380_01.png 920w", "/en/static/7c3f40b66d74a5deceab224b5e2dcabe/00d43/IN-Mikro_380_01.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7c3f40b66d74a5deceab224b5e2dcabe/c0255/IN-Mikro_380_01.png",
              "alt": "INSTAR IN-Mikro 380 Microphone for your IP Camera",
              "title": "INSTAR IN-Mikro 380 Microphone for your IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
...then connect the audio cable of your microphone with your camera´s audio-in. Done!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      